import logo_footer from "../../assets/img/dk-logo.svg"

function Footer(){

    return(
        <footer className="footer-section">
            <div className="footer-column-1">
                <img className="logo-footer" src={logo_footer} alt=""/>
                <h3 className="text-footer"> We build digital experience <br/> for the people who matters. </h3> 
                <p className="text-footer">© Digitalkultr 2022. All rights reserved.</p>
            </div>
            <div className="footer-column-2">
                <h3 className="heading-column"> this is dk. </h3>
                <ul className="menu-footer-column">
                    <li className="menu-list">
                        Get to Know us
                    </li>
                    <li className="menu-list">
                        Work With us
                    </li>
                    <li className="menu-list">
                        Our Playground
                    </li>
                    <li className="menu-list">
                        Career
                    </li>
                </ul>
            </div>
            <div className="footer-column-2">
                <h3 className="heading-column"> Follow Us </h3>
                <ul className="menu-footer-column">
                    <li className="menu-list">
                        Dribbble
                    </li>
                    <li className="menu-list">
                        Instagram
                    </li>
                    <li className="menu-list">
                        Linked In
                    </li>
                    <li className="menu-list">
                        Youtube
                    </li>
                </ul>
            </div>
            <div className="footer-column-2">
                <h3 className="heading-column"> Indonesia </h3>
                <ul className="menu-footer-column">
                    <li className="menu-list">
                        Bandung
                    </li>
                    <li className="menu-list">
                        Jl. Kupa No.5
                    </li>
                    <li className="menu-list">
                        40191
                    </li>
                    
                </ul>
            </div>
        </footer>
    )

}

export default Footer