import DkButton from "components/shared/button"

function Contactus(){

    return(
        <section className="contactus-section">
            <h2 className="contactus-text">
                Have a project in mind? <br/>
                Let’s work together! 👋
            </h2>
            <DkButton link="/contact" bName="Contact us"/>
        </section>
    )

}

export default Contactus