import React from "react";

import Home from './pages/Home';
import Studio from "./pages/Studio";
import Work from "./pages/Work";
import Insight from "./pages/Insight";
import Contact from "./pages/Contact";

import { 
	BrowserRouter,
	Route,
	Routes 
} from "react-router-dom";

const RoutesList = () => {

	return (
		<BrowserRouter>
			<Routes>
				<Route exact path="/" element={<Home />}/>
				<Route path="/studio" element={<Studio />}/>
				<Route path="/work" element={<Work />}/>
				<Route path="/insight" element={<Insight />}/>
				<Route path="/contact" element={<Contact/>}/>
			</Routes>
		</BrowserRouter>
	)
}

export default RoutesList