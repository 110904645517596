import Header from "components/common/header"

function Work (){

    return (
        <div className="wrapper">
           <Header />
           <main>
                <h1>
                    This is WorK Page
                </h1>
           </main>
        </div>
    )
}

export default Work