import dk_logo from "../../assets/img/dk-logo.svg"
import { Link } from "react-router-dom"

function Header (){

    return(
        <header className="navbar">
            <Link to="/">
                <img className="nav-logo" src={dk_logo} alt="logo digital kultur"/>
            </Link>
            <ul className="nav-list">
                <li className="nav-items">
                    <Link to="/studio" className="nav-link"> Studio</Link>
                </li>
                <li className="nav-items">
                    <Link to="/work" className="nav-link"> Work</Link>
                </li>
                <li className="nav-items">
                    <Link to="/insight" className="nav-link"> Insight</Link>
                </li>
                <li className="nav-items">
                    <Link to="/contact" className="nav-link"> Work with us</Link>
                </li>
            </ul>
        </header>
    )

}

export default Header