import './App.scss';
import RoutesList from "./routes.js";

function App() {

  return (
      <RoutesList />
  );
}

export default App;
