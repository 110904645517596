import DkButton from "components/shared/button"

function Hero (){

    return(
        <section className="hero-section">
            <h1 className="hero-title">in digital kultr. We build <br/> digital experience for the <br/> people who matters.</h1>
            <DkButton link="/studio" bName="Our Playground" />
        </section>
    )

}

export default Hero