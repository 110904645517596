import arrow_button from "../../assets/img/button-arrow.svg"

const Latestproject = (props) => {
	const { listData } = props;

	return(
		<section className="latest-project-section">
			<article className="project-wrapper">
				{
					Array.isArray(listData) &&
					(
						listData.length > 0
						? listData.map((item, index) => {
							let ganjil = index % 2 === 0;
							return (
								<figure className={ganjil ? "project-card-left" : "project-card-right"} key={index}>
									<div className="image-wrapper">
										<img className="project-pic" src={item.projectThumbnail.url} alt={`${item.projectName}`} />
									</div>
									<figcaption className="project-data">
										<h2 className="project-name">{item.projectName}</h2>
										<p className="project-desc">{item.projectDesc}</p>
										<a className="project-button" href="digitalkultr.com"> Launch Project < img className="button-icon" src={arrow_button} alt=""/></a>
									</figcaption>
								</figure>
							)
						})
						: <h1>NO DATA</h1>
					)
				}
			</article>
		</section>
	)
}

export default Latestproject