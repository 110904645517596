import React, { Component } from "react";
import arrow_button from "assets/img/button-arrow.svg"
import { Link } from "react-router-dom";

class DkButton extends Component { 

    render(){
        return(
            <Link to={this.props.link} className="dk-button" href="digitalkultr.com">{this.props.bName} 
                <img className="button-icon" src={arrow_button} alt=""/>
            </Link>
        )
    }

}

export default DkButton