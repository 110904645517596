

function Video(){

    return(
        <section className="video-section">
            <video className="dk-reels" width="100%" height="100%" autoPlay={true} muted={true} loop={true} playsInline="" id="showreel">
                <source src="https://player.vimeo.com/external/554304788.hd.mp4?s=1516ea973efafdd8c0f9612cc1a406ceab22b4ee&amp;profile_id=175" type="video/mp4"/>
            </video>
        </section>
    )

}

export default Video