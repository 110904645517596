import axios from "axios";
import errorResponseHandler from "services/axios/errorResponseHandler";
const id = `xzqtstl3yyky`;
const tokenCode = `7sbgBKuoovb0O1tiy9CXG-NeaNwNEp2gaTZjyfHZPgg`;
const MAIN_HOST = `https://graphql.contentful.com/content/v1/spaces/${id}`;

const instance = axios.create({
  baseURL: MAIN_HOST,
});

instance.interceptors.request.use(
  (config) => {
    const userToken = `Bearer ${tokenCode}`;
    config.headers.Authorization = userToken;
    return config;
  },
  (error) => {
    return error;
  }
);

instance.interceptors.response.use((response) => response, errorResponseHandler);

export const homeContent = () => {
  const query = `{
    homeProjectCollection{
      items{
        projectName
        projectDesc
        projectThumbnail{
          url
        }
      }
    } 
  }`;

  return instance.post("/", { query });
}

