import React from "react"
import Header from "components/common/header"

function Contact (){

    return (
        <React.Fragment>
           <Header />
           <main>
                <h1>
                    This is contact Page
                </h1>
           </main>
        </React.Fragment>
    )
}

export default Contact