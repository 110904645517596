import about_img1 from "assets/img/about-1.webp"
import about_img2 from "assets/img/about-2.webp"
import DkButton from "components/shared/button"


function About(){

    return(
        <section className="brief-about-section">
            <div className="image-about">
                <img src={about_img1} className="about-image1" alt=""/>
                <img src={about_img2} className="about-image2" alt=""/>
            </div>
            <article className="brief-about-us">
                <div className="about-text">
                    <p className="brief-about-us-text">
                        We’re a small team of versatile creatives who value quality and having fun in their work.
                    </p>
                    <DkButton link="/studio" bName="Get to know us"/>
                </div>
            </article>
        </section>
    )
}

export default About