import DkButton from "components/shared/button"

const Latestwork = (props) => {
	const { children } = props;

	return(
		<section className="latestwork-section">
			<div className="section-title">
				<h2 className="latestwork-title">Some of our <br/> most recent <br/> works.</h2> 
				<p className="latestwork-subtitle">We’re a lean creative agency that uses design and code to solve problems. Over the years we had the chance to experience and work on many amazing projects and challenges.</p>
			</div>
			{children}
			<div className="latestwork-cta">
				<h2 className="latestwork-cta-title">
				We work with modern teams to create tools, services, <br/> and products that connect with people.
				</h2>
				<DkButton link="/work" bName="View More Project" />
			</div>
		</section>
	)
}

export default Latestwork