import React, { useEffect, useState } from "react"
import Header from "components/common/header"
import Footer from "components/common/footer"
import Hero from "pages/Home/hero"
import About from "pages/Home/about"
import Contactus from "pages/Home/contactus"
import Latestwork from "pages/Home/latestwork"
import Video from "pages/Home/video"
import Latestproject from "pages/Home/latestproject"
import { homeContent } from "services/backend"; 

const Home = () => {
	const [listData, setListData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getHomeContent();
	},);

	const getHomeContent = async () => {
		setIsLoading(true);
		try {
			let response = await homeContent();
			let list = response.data.data.homeProjectCollection.items || [];
			setListData(list);
		} catch (error) {
			setListData([isLoading]);
		} finally {
			setIsLoading(false);
		}
	}

	return(
		<React.Fragment>
			<Header/>
			<Hero/>
			<Video/>
			<Latestwork>
				<Latestproject listData={listData} />
			</Latestwork>
			<About/>
			<Contactus/>		
			<Footer/>
		</React.Fragment>
	)
}

export default Home